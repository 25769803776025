


















































































































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import Lat from "@/shared/components/Lat.vue";
import PlacementRequestModel from "@/settings/models/placements/PlacementRequestModel";
import {
  AppSection,
  Dictionary,
  DictionaryType,
  PLACEMENT_DICTIONARIES,
} from "@/shared/models";
import { AdType } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import NetworkIntegrationApplicationResponseModel from "@/settings/models/placements/NetworkIntegrationApplicationResponseModel";
import { NetworkType } from "@/settings/models/networks-management/NetworkIntegrationResponseModel";

@Component({
  components: { Lat },
})
export default class PlacementForm extends Vue {
  @Prop() value!: PlacementRequestModel;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: false }) isEditable!: boolean;

  @Ref("form") readonly form!: VueForm;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly booleanRule = [ValidUtil.boolean(this.$lang("validation.required"))];
  readonly adTypeItems = Object.values(AdType).map(
    (type: AdType) =>
      new Dictionary(type, this.$lang(`views.perMin.${type.toLowerCase()}`))
  );
  readonly latItems = [
    {
      text: this.$lang("appAdmin.placementManagement.tableHeaders.lat"),
      value: true,
    },
    {
      text: this.$lang("appAdmin.placementManagement.latDisabled"),
      value: false,
    },
  ];
  isValid = false;
  localValue: PlacementRequestModel = PlacementRequestModel.ofSource(
    this.value
  );

  get localNetworkIntegrationApplicationId(): number {
    return this.localValue.networkIntegrationApplicationId;
  }

  set localNetworkIntegrationApplicationId(value: number) {
    this.localValue.networkIntegrationApplicationId = value;
  }

  get localPlacementId(): string {
    return this.localValue.placementId;
  }

  set localPlacementId(placementId: string) {
    this.localValue.placementId = placementId;
    this.checkPlacementIdUniqueness(placementId);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.placementsStore.isSavingInProgress;
  }

  get isPlacementIdUnique(): boolean {
    return this.$store.state.placementsStore.isPlacementIdUnique;
  }

  get networkIntegrationApplicationItems(): Array<NetworkIntegrationApplicationResponseModel> {
    return this.$store.state.placementsStore.networkIntegrationApplications.map(
      ({
        id,
        networkName,
        aliasName,
      }: NetworkIntegrationApplicationResponseModel) => ({
        text: aliasName ?? networkName,
        value: id,
      })
    );
  }

  get deviceTypeItems(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.DEVICE_TYPES
    ].values.filter(({ value }: Dictionary) =>
      ["tablet", "phone"].includes(value)
    );
  }

  get waterfallIdItems(): Array<string> {
    return this.$store.state.dictionaryStore[
      DictionaryType.WATERFALLS_ID
    ].values.map(({ value }: Dictionary) => value);
  }

  get placementIdErrorMessages(): Array<string> {
    return this.isPlacementIdUnique
      ? []
      : [this.$lang("shared.errors.notUnique")];
  }

  get isIos(): boolean {
    return (
      this.$store.getters.applicationsById.get(this.applicationId)
        ?.platformType === "ios"
    );
  }

  get isGp(): boolean {
    return (
      this.$store.getters.applicationsById.get(this.applicationId)
        ?.platformType === "android"
    );
  }

  @Watch("applicationId", { immediate: true })
  private watchApplicationId(value: string) {
    Promise.all([
      this.$store.dispatch("loadDictionaries", {
        app: value,
        dictionaryTypes: PLACEMENT_DICTIONARIES,
      }),
      this.$store.dispatch("loadNetworkIntegrationApplications", {
        applicationId: value,
        params: { networkType: NetworkType.REVENUE },
      }),
    ]);
  }

  @Watch("localNetworkIntegrationApplicationId")
  watchNetworkIntegrationApplicationId() {
    if (this.localPlacementId) {
      this.checkPlacementIdUniqueness(this.localPlacementId);
    }
  }

  destroyed() {
    this.$store.commit("setPlacementIdUniqueness", true);
  }

  handleCancel() {
    this.$router.push({
      name: AppSection.PLACEMENTS,
      params: { id: this.applicationId },
      query: this.$route.query,
    });
  }

  checkPlacementIdUniqueness = debounce((placementId) => {
    this.$store.dispatch("checkPlacementIdByUnique", {
      placementId,
      networkIntegrationApplicationId:
        this.localNetworkIntegrationApplicationId,
    });
  }, 500);

  handleSave() {
    if (!this.form.validate()) {
      return;
    }

    if (this.isGp) {
      this.localValue.lat = false;
    }

    this.$emit("save", this.localValue);
  }
}
