import PlacementResponseModel from "./PlacementResponseModel";
import { AdType } from "@/shared/models";

export default class PlacementRequestModel {
  constructor(
    public placementId = "",
    public networkIntegrationApplicationId = 0,
    public adType = AdType.REWARDED,
    public adUnit = "",
    public spotId?: string,
    public deviceType = "phone",
    public lat: null | boolean = null,
    public waterfallId?: string,
    public id?: number
  ) {}

  static ofSource(
    model: PlacementRequestModel | PlacementResponseModel
  ): PlacementRequestModel {
    return new PlacementRequestModel(
      model.placementId,
      model.networkIntegrationApplicationId,
      model.adType,
      model.adUnit,
      model.spotId,
      model.deviceType,
      model.lat,
      model.waterfallId,
      model.id
    );
  }
}
